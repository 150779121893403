'use client';

import type { FC, ReactNode } from 'react';
import React, { useMemo, useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import type { UserInfoInterface } from '../types/user';
import getLiveChatProps from '~libs/get-live-chat-props';
import { useLiveChat } from '~hooks/use-live-chat';

interface LiveChatProviderProps {
  children: ReactNode;
  userInfo: UserInfoInterface | undefined;
  locale: string;
}

const customStyle = {
  visibility: {
    desktop: {
      xOffset: 15,
      yOffset: 15,
      position: 'br',
    },
    mobile: {
      xOffset: 0,
      yOffset: 200,
      position: 'cr',
    },
  },
};

const LiveChatProvider: FC<LiveChatProviderProps> = ({
  children,
  userInfo,
  locale,
}) => {
  const { data: liveChatInfo } = useLiveChat();
  const tawkMessengerRef = useRef<any>();
  const onLoad = (): void => {
    if (!tawkMessengerRef.current && !userInfo) {
      return;
    }
    const attribute = {
      name: userInfo?.username,
      hash: userInfo?._id,
      store: `${userInfo?.agent}/${userInfo?.username}`,
      phone: userInfo?.phoneNumber || '-',
    };
    tawkMessengerRef.current.setAttributes(attribute, (error) => {
      if (error) {
        console.error('setAttributes error', error);
      }
    });
  };

  const liveChat = useMemo(() => {
    const liveChatProps = getLiveChatProps(liveChatInfo, locale);
    if (!liveChatProps.propertyId && !liveChatProps.widgetId) {
      return;
    }
    return (
      <TawkMessengerReact
        customStyle={customStyle}
        onLoad={onLoad}
        propertyId={liveChatProps.propertyId}
        ref={tawkMessengerRef}
        widgetId={liveChatProps.widgetId}
      />
    );
  }, [liveChatInfo, locale]);

  return (
    <>
      {children}
      {liveChat}
    </>
  );
};

export default LiveChatProvider;
