'use client';

import type { AnyAbility } from '@casl/ability';
import { Ability, AbilityBuilder } from '@casl/ability';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import type { CaslAbilityInterface } from 'types/wallet';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';

const AbilityProviderContext = createContext<{
  ability: AnyAbility | undefined;
  updateAbilities: (newAbilities: CaslAbilityInterface[]) => void;
}>({
  ability: undefined,
  updateAbilities: () => undefined,
});

export const AbilityProvider = (context) => {
  const { children } = context;
  const { data: userData } = useUserInfo();
  const { data: userWallet } = useWalletInfo(
    userData?.selectedWallet.objectId || '',
  );
  const walletAbilities = userWallet?.allowedGameProviders || [];
  const { build } = new AbilityBuilder(Ability);
  const [ability, _] = React.useState<AnyAbility>(build());
  // Initial Abilities
  useMemo(() => {
    if (
      !walletAbilities ||
      !Array.isArray(walletAbilities) ||
      !walletAbilities.length
    ) {
      ability.update([
        {
          action: 'read',
          subject: ['casino', 'gaming', 'mini-games', 'sportsbook'],
        },
      ]);
      return;
    }
    ability.update(walletAbilities);
  }, [walletAbilities]);

  const updateAbilities = useCallback(
    (newAbilities: CaslAbilityInterface[]) => {
      return ability.update(newAbilities);
    },
    [ability],
  );
  return (
    <AbilityProviderContext.Provider
      value={{
        ability,
        updateAbilities,
      }}
    >
      {children}
    </AbilityProviderContext.Provider>
  );
};

export const useAbilities = (): any => {
  return useContext(AbilityProviderContext);
};
