import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/app/[locale]/(authorized)/layout.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/components/layout/default-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AbilityProvider"] */ "/app/apps/web/context/abilities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AgentProvider"] */ "/app/apps/web/context/agent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/context/announcement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebSocketProvider"] */ "/app/apps/web/context/socket.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/i18n/index.ts");
