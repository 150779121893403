import type { LiveChatInterface } from '../types/live-chat';
import type { Config } from '~hooks/use-request';
import useRequest from '~hooks/use-request';
import { useUserInfo } from '~hooks/use-user';

export const useLiveChat = (config?: Config<LiveChatInterface[]>) => {
  const { data: userInfo } = useUserInfo();
  return useRequest<LiveChatInterface[]>(
    userInfo
      ? {
          url: '/live-chats',
        }
      : undefined,
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};
