import type { LiveChatInterface } from '../types/live-chat';
import { StatusEnum } from '~constants/financial';
import { LiveChatProviderEnum } from '~constants/etc';

const isValidDirectLink = (directLink: string): boolean => {
  if (!directLink) {
    return false;
  }
  const liveChatProps = getPropsByDirectLink(directLink);
  return Boolean(liveChatProps?.propertyId) && Boolean(liveChatProps?.widgetId);
};

const getPropsByDirectLink = (
  directLink: string,
):
  | {
      propertyId: string;
      widgetId: string;
    }
  | undefined => {
  if (!directLink) {
    return;
  }
  const linkSplit = directLink.split('/');
  const propertyId = linkSplit[4];
  const widgetId = linkSplit[5];
  return {
    propertyId,
    widgetId,
  };
};

const getLiveChatProps = (
  liveChat: LiveChatInterface[] | undefined,
  locale: string,
): {
  propertyId: string;
  widgetId: string;
} => {
  const liveChatFiltered =
    liveChat?.filter((res) => {
      const isMatchProvider = res.provider === LiveChatProviderEnum.Tawkto;
      const isStatusActive = res.status === StatusEnum.Active;
      const isMatchLanguage = res.language === locale;
      const isValidDirectChatLink = isValidDirectLink(res.directChatLink);
      return (
        isMatchProvider &&
        isStatusActive &&
        isMatchLanguage &&
        isValidDirectChatLink
      );
    }) || [];

  return liveChatFiltered.reduce(
    (props, liveChatInfo) => {
      const liveChatProps = getPropsByDirectLink(liveChatInfo.directChatLink);
      props.propertyId = liveChatProps?.propertyId || '';
      props.widgetId = liveChatProps?.widgetId || '';
      return props;
    },
    { propertyId: '', widgetId: '' },
  );
};

export default getLiveChatProps;
