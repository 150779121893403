export enum StatusEnum {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Finished = 'finished',
  InProgress = 'in-progress',
  Success = 'success',
  Waiting = 'waiting',
  Open = 'open',
  Pending = 'pending',
  Refund = 'refund',
  Active = 'active',
  Disabled = 'disabled',
  Claimed = 'claimed',
  Redeemed = 'redeemed',
}

export enum PaymentMethodProviderEnum {
  BankTransfer = 'bank-transfer',
  OnlinePayment = 'online-payment',
}

export enum FinancialConfigKeys {
  RequireVerifyAccount = 'require-verified-user-before-withdrawal',
}

export enum OnlinePaymentMethodProviderEnum {
  BigPay = 'bigpay',
}

export enum TransactionTypeEnum {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export const TransactionStatusOptions = [
  {
    label: 'สถานะทั้งหมด',
    value: '',
  },
  {
    label: 'กำลังดำเนินการ',
    value: StatusEnum.Waiting,
  },
  {
    label: 'สำเร็จ',
    value: StatusEnum.Finished,
  },
  {
    label: 'ถูกยกเลิก',
    value: StatusEnum.Cancelled,
  },
];

export const TransactionTypeOptions = [
  {
    label: 'ประเภทธุรกรรมทั้งหมด',
    value: '',
  },
  {
    label: 'ฝากเงิน',
    value: TransactionTypeEnum.Deposit,
  },
  {
    label: 'ถอนเงิน',
    value: TransactionTypeEnum.Withdraw,
  },
];
