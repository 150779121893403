'use client';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/th';
import React, { useEffect, type FC, type PropsWithChildren } from 'react';
import { appsignal, eventErrorSpan } from '@packages/sentry';
import type { UserInfoInterface } from '../../../types/user';
import LiveChatProvider from '~context/live-chat-provider';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(timezone);

const errorHandling = (event: ErrorEvent): void => {
  void appsignal.send(eventErrorSpan(event.error));
};

const AuthorizedLayoutClient: FC<
  PropsWithChildren & {
    locale: string;
    userInfo: UserInfoInterface | undefined;
  }
> = ({ children, locale, userInfo }) => {
  dayjs.locale(locale);
  useEffect(() => {
    window.addEventListener('error', errorHandling);
    return () => {
      window.removeEventListener('error', errorHandling);
    };
  }, []);

  return (
    <LiveChatProvider locale={locale} userInfo={userInfo}>
      {children}
    </LiveChatProvider>
  );
};

export default AuthorizedLayoutClient;
